.button-link {
    text-decoration: none;
    font-weight: bold;
}

.button-link:hover {
    text-decoration: underline;
    color: red;
}

.button-link:hover span {
    text-decoration: underline;
    color: red;
}

.button-container {
    display: inline-block;
}

span {
    color: #000000;
}