.main-container {
  position: fixed;
  right: 40px;
  bottom: 40px;
  z-index: 5000000;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: end;
}

.chat-button {
  background-color: rgb(177, 177, 177);
  border-radius: 50%;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-conversation {
  border-radius: 20px;
  padding: 30px;
  background-color: rgb(235, 235, 235);
  margin-bottom: 20px;
  max-width: 300px;
  color: #000000;
}

.hidden-conversation {
  width: 0px;
  height: 0px;
}

#conversation {
  transition: 200ms;
}

.chat-link {
  cursor: pointer;
}
.chat-link:hover {
  color: #001524;
}

.bot-response {
  border-radius: 20px;
  padding: 30px;
  background-color: rgb(235, 235, 235);
  margin-bottom: 20px;
  max-width: 300px;
  color: #ffffff;
}

.bot-response-hidden {
  width: 0px;
  height: 0px;
}

.response {
  max-width: 400px;
  background-color: rgb(235, 235, 235);
  color: #000000;
  border-radius: 25px;
  padding: 25px;
}

.response-hidden {
  display: none;
}