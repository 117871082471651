.rounded-red-span {
    background-color: #ff0000;
    color: #ffffff;
    border-radius: 50%;
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: xx-large;
}