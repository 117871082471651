p {
  font-size: 1.3em;
}

.stylish {
  font-family: 'Abril Fatface', cursive;
}

.heading {
  font-size: 3.2em;
}

.pink {
  background-color: #FCD6D3;
}

.subheading {
  font-size: 2em;
}

a {
  color: #0000ff;
}

a:hover {
  color: #ff0000;
}

.profile-image-container {
  height: 100px;
  width: 100px;
  background-position: center;
  background-size: contain;
  border-radius: 50%;
}

ul li {
  font-size: 1.3em;
}