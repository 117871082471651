.text-justify {
  text-align: justify;
}

.hyperlink {
  cursor: pointer;
}

.hyperlink:hover {
  text-decoration: underline;
}

.text-justify {
  text-align: justify;
}

.event-type {
  color: yellow
}

.resource-link {
  text-decoration: none;
  color: #ffffff;
}

.resource-link:hover {
  text-decoration: underline;
  color: #ff0000;
}

.hyperlink-span {
  cursor: pointer;
  background-color: #ff0000;
  color: #ffffff;
  border-radius: 50%;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: xx-large;
}

.image-with-pointer {
  cursor: pointer;
}