.stylish {
  font-family: 'Abril Fatface', cursive;
}

.clickable-profile {
  cursor: pointer;
}

.large-image-container {
  float: left;
  width: 210px;
  height: 210px;
  margin-right: 20px;
  border-radius: 50%;
  background-position: center;
  background-size: cover; 
}