.footer {
  background-color: #0D131A;
}

a {
  font-size: 1.1em;
}

.sub-footer {
  background-color: #000000;
  color: #ffffff;
}

a:hover {
  color: #ff0000;
}

.nav-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.nav-list li {
  line-height: 2em;
}

.footer-link {
  color: white;
  text-decoration: none;
}

.footer-ext-link {
  text-decoration: none;
}